@import "~bulma/sass/utilities/initial-variables";
// Bulma overrides
$primary: #004eea;
$danger: #be0912;
$warning: #e3d60e;
$success: #277914;
$info: #006cb8;
$family-sans-serif: 'Montserrat', sans-serif;
$variable-columns: false;
$content-heading-weight: 500;
$title-size: $size-4;

@import "~bulma/bulma";
@import "~react-redux-toastr/src/styles/index";

// Kendo overrides
$font-size: 12px;
$grid-cell-padding-y: 0.5em;
$grid-cell-padding-x: 0.75em;
$grid-cell-horizontal-border-width: 0;
$grid-cell-vertical-border-width: 0;
// $grid-alt-bg: #eaf4fa;
//$grid-selected-bg: #edf2ff;
$grid-selected-bg: #E7EEFD;
$grid-hovered-bg: #E7EEFD;
$grid-sorted-bg: transparent;
$primary: $info;
$text-color: #353535;
$grid-alt-bg: white;
$grid-bg: white;
$base-bg: #F5F5F5;

//Global Values
$sidebar-bg: #EBEBEB;

@import "~@progress/kendo-theme-default/scss/grid/_index.scss";
@import "~@progress/kendo-theme-default/scss/popup/_index.scss";
@import "~@progress/kendo-theme-default/scss/input/_index.scss";
@import "~@progress/kendo-theme-default/scss/datetime/_index.scss";
@import "~@progress/kendo-theme-default/scss/progressbar/_index.scss";
@import "~@progress/kendo-theme-default/scss/stepper/_index.scss";
@import "~@progress/kendo-theme-default/scss/panelbar/_index.scss";
@import "~@progress/kendo-theme-default/scss/tabstrip/_index.scss";
@import "~@progress/kendo-theme-default/scss/slider/_index.scss";

@import "_mixins";

#configurationChartSection .highcharts-point {
  stroke: rgb(102, 102, 102);
}

.highcharts-point {
  stroke: white;
}


.highcharts-area.is-danger {
  fill: $danger;
}

.highcharts-point.is-danger {
  fill: $danger;
}

.highcharts-area.is-info {
  fill: $info;
}

.highcharts-point.is-info {
  fill: $info;
}

.highcharts-area.is-success {
  fill: $success;
}

.highcharts-point.is-success {
  fill: $success;
}

.highcharts-point.danger {
  fill: $danger;
  stroke: $danger;
}

/* Grid overrides */

.k-grid tr {
  background-color: #FFFFFF;
}

.k-group-cell,
.k-grid .k-grouping-row td {
  background-color: #EDEDED;
  border-bottom: 1px solid #EDEDED !important;
}

.k-grid td.nowrap {
  white-space: nowrap;
}

.k-grid a.link {
  color: #3273dc;
}

.k-grid-content {
  overflow-y: auto;
}

.k-grid table {
  max-width: 100%;
}

.k-grid-header {
  padding: 0 !important;
}

.k-grid-wrapper {
  .k-grid-header-wrap {
    border-right-width: 0;
  }
}

//Icons
.icon i {
  display: flex;
  align-items: center;
  justify-content: center;
}

// Like Icons
.circle {
  width: 24px;
  height: 24px;
  background-color: #F7DCD4;
  border-radius: 50%;
  position: relative;
}

.x-mark {
  position: absolute;
  width: 16px;
  height: 2px;
  background-color: #D84C24;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%) rotate(45deg);
}

.x-mark::after {
  content: '';
  position: absolute;
  width: 16px;
  height: 2px;
  background-color: #D84C24;
  transform: rotate(90deg);
  top: 0;
  left: 0;
}

.green-circle {
  background-color: #E4F2ED;
}

.checkmark {
  position: absolute;
  width: 6px;
  height: 14px;
  border-right: 2px solid #7ABEA7;
  border-bottom: 2px solid #7ABEA7;
  transform: rotate(45deg);
  top: 26%;
  left: 50%;
  transform: translate(-50%, -30%) rotate(45deg);
}

//Main Navigation
.main-nav {
  padding-left: 0;
  padding-right: 0;
  min-width: 0;
  border-left: 1px solid #DBDBDB;
}

//Main Container
.main-nav-bg {
  background: $base-bg;
}

//Manage Dashboards Grid
.templateTypeColumn {
  padding-left: 18px !important;
}

.k-widget.k-grid.managedashboards,
.k-widget.k-grid.adddashboard {

  th:first-child,
  td:first-child {
    text-align: center;
  }
}

// Privacy
.privacy {
  p:not(:last-child) {
    margin-bottom: 1rem;
  }

  .subtitle {
    margin-bottom: .5rem;
  }

  .inside-aligned {
    padding-top: 5px;
    padding-left: 10px;
    list-style: none;
    counter-reset: li;
  }

  .inside-aligned li {
    counter-increment: li;
  }

  @supports (grid-template-columns: subgrid) {
    .inside-aligned {
      display: grid;
      grid-template-columns: min-content 1fr;
    }

    .inside-aligned li {
      grid-column: 1 / -1;
      display: grid;
      grid-template-columns: subgrid;
    }
  }

  .inside-aligned li::before {
    content: "-";
    margin-right: 0.2rem;
  }

  .inside-aligned-right li::before {
    text-align: end;
  }
}

// Task & Diagnostics Grid
.k-grid-wrapper.task-grid,
.k-grid-wrapper.diagnostics-grid,
.k-grid-wrapper.building-grid,
.k-grid-wrapper.deployment-grid,
.k-grid-wrapper.equipment-grid,
.k-grid-wrapper.point-grid,
.k-grid-wrapper.equipment-configuration-grid {
  .k-grid tr {
    cursor: pointer;
  }
}

.k-grid-wrapper.scrollable {
  .k-grid-header {
    padding: 0 16px 0 0 !important;
  }

  .k-grid-header-wrap {
    border-right-width: 1px;
  }
}

// Task summary
.task-summary {
  width: 100%;

  .k-panelbar {

    .k-link.k-header,
    .k-link.k-header.k-state-selected {
      cursor: pointer;
      color: inherit;
      background-color: transparent;
      font-weight: 500;
      box-shadow: none;
    }
  }

  .k-animation-container-relative {
    z-index: 1;
  }

  td.row-multi {
    padding: 0;

    >div {
      padding: 7px 10.5px;

      &:not(:last-child) {
        border-bottom: 1px solid $grid-header-border;
      }
    }
  }
}

// Task Grid Export Menu
.menu-popup-content {
  margin-top: 5px;
  border-radius: 3px;

  .menu-container {
    background: #fff;
    border-radius: 3px;
    .k-menu {
      min-width: 180px;

      .k-menu-item {
        color: #656565;

        &:focus {
          box-shadow: none !important;
        }
      }
    }
  }
}

// edge browser only
.edge .k-grid-wrapper.scrollable {
  .k-grid-header-wrap {
    border-right-width: 0;
  }
}

.k-pager-numbers .k-link:hover,
.k-pager-numbers .k-link.k-state-hover {
  background-color: #ededed;
}

// inside widget
.table-widget-container .k-grid-header {
  padding: 0 16px 0 0 !important;
}

.table-widget-container>.react-resizable-handle {
  height: 16px;
}

.k-grid-header-show {
  display: inherit !important;
}

.k-grid-nowrap {
  white-space: nowrap;
  word-break: unset;
}

.k-grid td:last-child,
.k-grid th:last-child {
  min-width: 140px;
}

.tile-grid td:last-child,
.tile-grid th:last-child {
  min-width: unset !important;
}

tr.k-state-selected>td {
  color: $grid-selected-text;
  background-color: $grid-selected-bg;
}

tr.k-grid-edit-row {
  background-color: $grid-selected-bg;
}

/* Tile grid widget preview dialog */
.tile-widget {
  & .k-grid tbody tr:hover {
    background-color: unset;
  }

  & .k-grid tbody tr td {
    padding: 0.5rem;
    width: 50%;
    vertical-align: top;
    cursor: pointer;

    &:hover:not(.k-state-selected) {
      background-color: #ededed;
    }
  }

  & .outer {
    margin: 0;
    display: flex;
    flex-direction: column;

    & .title-wrapper {
      margin-bottom: 0.75em;

      & .title {
        margin-bottom: 0;
        font-size: 1.2rem;
      }
    }
  }

  & .inner {
    display: flex;

    & .inner-item.right {
      width: 100%;
      padding: 0 0.75rem;
    }

    & .desc-tag-wrapper {
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      height: 100%;

      & .text-desc {
        font-size: 0.95rem;
      }

      & .text-tag {
        font-size: 0.8rem;
        display: flex;

        & .tag-label {
          margin-right: 0.5rem;
          word-break: keep-all;
        }

        & .tag-value {
          min-height: 30px;
          word-break: break-word;
        }
      }
    }
  }
}

.modal-content-reprocessdiagnostics {
  height: 100vh;
  align-items: center;
  display: flex;
  .box{
    width: 100%;
  }
}

@media screen and (max-width: 1536px) {
  .modal-content-reprocessdiagnostics {
   .dropdown-content{
    translate: 0 -130px;
   }
 }
}

.modal-main-wrapper{
  display: flex;
  flex-direction: column;
  gap: 20px;
}

.modal-action-button {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 10px;
}

.modal-action-button-cancel {
  border-bottom: 2px solid $primary !important;
  border-radius: 0px !important;
  color: $primary !important;
}

// Reprocess Diagnostics
.reprocess-diagnostics-fields {
  display: flex;
  flex-direction: column;
  gap: 5px;
  padding-bottom: 30px;
}

.reprocess-diagnostics-field {
  display: flex;

    .dropdown{
      position: absolute!important;
      translate: 0px -12px;
      z-index: 1;
    }

  p {
    min-width: 30%;
  }

  @media screen and (min-width: 769px), print {
     .columns:not(.is-desktop) {
      display: block;
    }
  }
}

.intervals{
  div{
    div{
      gap: 10px;
    }
  }
}

/* Toastr styles */

.redux-toastr .toastr {
  opacity: 1;

  &.rrt-error {
    background-color: $danger;
  }

  &.rrt-success {
    background-color: $success;

    & .rrt-progressbar {
      background-color: #ffffff;
    }
  }

  &.rrt-warning {
    background-color: $warning;
  }

  // close 'x' button
  & .close-toastr span {
    top: 16px;
  }
}

.redux-toastr .top-center {
  top: 40%;
}

.redux-toastr .toastr .close-toastr {
  top: 0;
  height: 10%;
}

:root {
  --colors-primary: blue;
  --colors-secondary: white;
  --headerBgColor: #777777;
  --headerTextColor: #ffffff;
  --navigationBgColor: #444444;
  --navigationTextColor: #ffffff;
  --applicationBgColor: #ffffff;
  --containerBorderColor: #c0c0c0;
}

/* Custom Styles */
.field-body-column {
  display: flex;
  flex-direction: column;

  &>* {
    margin-right: 0 !important;
  }
}

/* Bulam Headings */
.title,
.subtitle {
  word-break: break-all;
}

/* Kendo Horizontal */

.k-numerictextbox.is-horizontal .k-numeric-wrap input,
.k-datepicker.is-horizontal .k-dateinput-wrap input {
  font-size: 16px;
}

/* Bulma Control Styles */

.help.readonly {
  padding-left: 9px;
}

.box.multiselect {
  width: 100%;
  border-radius: 0;
  padding: 0.5rem;
}

.box.multiselect input[type="checkbox"] {
  margin-right: 0.75rem;
}

input[type="text"]::-ms-clear {
  display: none;
}

.input[readonly] {
  border: none;
}

.select {
  &.highlighted {
    &::after {
      border-color: #fff !important;
    }
    .form-control {
      color: #fff;
      background-color: #004EEA;
    }
  }
  .form-control {
    option {
      color: #363636;
      background-color: #fff;
    }
  }
}

.control.has-icons-right .icon.is-right {
  right: 0;
}

.control.has-icons-left .icon,
.control.has-icons-right .icon {
  color: #7a7a7a;
}

/* Modal styles */
.hiddenScrollbars {
  overflow: hidden;
}

.modal-content.full,
.modal-content.md {
  width: 90%;
}

.modal-content.full,
.modal-content.md,
.modal-content.half {
  margin: 0 20px;

  .box.filter-list.filter {
    padding: 0.9rem;
  }

  & .filter-list {
    display: flex;
    flex-wrap: wrap;
    align-items: flex-start;
    justify-content: flex-start;
    align-content: flex-start;
    justify-items: flex-start;
  }

  & .filter-list-item {
    margin-bottom: 8px;
    width: 50%;
    height: 36px;
    align-self: flex-start;
    justify-self: flex-start;
  }

  & .filter-dropdown-wrapper {
    width: 100%;
    align-self: flex-start;
    justify-self: flex-start;
  }
}

.modal-content.full.scrollable {
  overflow: hidden;
}

.modal-content.full.scrollable>.box {
  display: flex;
  flex-direction: column;
  max-height: calc(100vh - 40px);

  .modal-header {
    flex-shrink: 1;
  }

  .modal-main {
    flex-shrink: 1;
    flex-grow: 1;
    overflow: auto;
  }

  .modal-footer {
    flex-shrink: 1;
  }
}

.modal-content.md {
  width: 50% !important;
}

@media screen and (min-width: 769px),
print {

  .modal-content,
  .modal-card {
    margin: 0;
  }
}

.modal-content.half {
  width: 35%;

  @media screen and (max-width: 1402px) and (min-width: 1108px) {
    width: 45%;
  }

  @media screen and (max-width: 1107px) and (min-width: 888px) {
    width: 55%;
  }

  @media screen and (max-width: 887px) and (min-width: 769px) {
    width: 63%;
  }

  @media screen and (max-width: 768px) and (min-width: 321px) {
    width: 65%;
  }

  @media screen and (max-width: 320px) {
    width: 81%;
  }
}

.popup-filter-group-container {
  @media screen and (max-width: 768px) and (min-width: 659px) {
    line-height: 50px;
  }
}

.popup-dynamic-container {
  @media screen and (max-width: 768px) and (min-width: 765px) {
    width: max-content;
  }

  @media screen and (max-width: 764px) and (min-width: 526px) {
    height: 206px;
    columns: unset !important;
  }
}

.widget-filter-title {
  display: flex;
  font-size: 1.5rem;
  padding-left: 10px;
  margin-bottom: 0 !important;

  span {
    padding-left: 12px;
  }

  @media screen and (max-width: 452px) and (min-width: 351px) {
    font-size: 1.2rem;
  }

  @media screen and (max-width: 350px) {
    font-size: 1rem;
  }
}

.widget-filter-box {
  height: 370px;

  @media screen and (max-width: 768px) and (min-width: 765px) {
    height: 460px;
  }

  @media screen and (max-width: 658px) and (min-width: 526px) {
    height: 440px;
  }

  @media screen and (max-width: 525px) and (min-width: 453px) {
    height: 440px;
  }

  @media screen and (max-width: 452px) {
    height: 455px;
  }
}

.popup-filter-group-label {
  display: flex;
  align-items: center;
  padding-left: 15px !important;

  @media screen and (max-width: 768px) and (min-width: 765px) {
    padding-left: 0 !important;
    height: 25px;
  }
}

.popup-filter-area {
  @media screen and (max-width: 768px) and (min-width: 765px) {
    padding-top: 0;
    padding-bottom: 0;
  }
}

.popup-filter-group-dropdown {
  padding: 0;

  @media screen and (max-width: 768px) and (min-width: 659px) {
    width: 200% !important;
  }

  @media screen and (max-width: 658px) and (min-width: 526px) {
    width: 265% !important;
  }

  @media screen and (max-width: 452px) {
    width: 116% !important;
  }

  @media screen and (max-width: 375px) and (min-width: 321px) {
    width: 125% !important;
  }
}

/* Dashboard Styles */

.box.templategallery {
  display: flex;
  flex-wrap: wrap;
  justify-content: left;
}

.templatethumb {
  margin: 5px;
  transition: all 0.5s;
  border: 1px solid black;
}

.templatethumb:hover {
  transform: scale(1.5);
  transform-origin: center;
}

.templatethumb.topleft:hover {
  transform-origin: top left;
}

.templatethumb.topcenter:hover {
  transform-origin: top center;
}

.templatethumb.topright:hover {
  transform-origin: top right;
}

.templatethumb.leftcenter:hover {
  transform-origin: left center;
}

.templatethumb.rightcenter:hover {
  transform-origin: right center;
}

.templatethumb.bottomleft:hover {
  transform-origin: bottom left;
}

.templatethumb.bottomcenter:hover {
  transform-origin: bottom center;
}

.templatethumb.bottomright:hover {
  transform-origin: bottom right;
}

/* Login styles */

.login {
  margin-top: 10px;
}

/* navbar styles*/

.navbar {
  /* stylelint-disable */
  background-color: var(--headerBgColor);
}

.navbar-item-separator {
  height: 30px;
  width: 1px;
  background: #fff;
  align-self: center;
}

@media screen and (max-width: 1023px) {
  .navbar-menu {
    background-color: var(--headerBgColor);
    //filter: invert(100%);
  }

  a.navbar-item:focus,
  a.navbar-item:focus-within,
  a.navbar-item:hover,
  a.navbar-item.is-active,
  .navbar-link:focus,
  .navbar-link:focus-within,
  .navbar-link:hover,
  .navbar-link.is-active {
    background-color: initial;
  }

  .navbar-item-separator {
    display: none;
  }
}

.navbar-item {
  color: var(--headerTextColor);

  .navbar-link::after {
    border-color: #fff;
  }
}

.navbar.is-transparent .navbar-dropdown {
  font-size: 1rem;
  background-color: var(--headerBgColor);

  a.navbar-item.nav-child {
    color: var(--headerTextColor);

    &:hover {
      color: var(--headerBgColor);
      background-color: var(--headerTextColor) !important;
    }
  }
}

a.navbar-item:hover,
a.navbar-item.is-active,
a.navbar-link:hover,
a.navbar-link.is-active,
.navbar-burger {
  color: var(--headerTextColor);
}

.navbar-item.navbar-burger {
  margin-left: inherit;
}

.navbar-toggle .fa {
  cursor: pointer;
}

/* NAVIGATION STYLES */

.overlay {
  z-index: 1;
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  opacity: 0;
  visibility: hidden;
  transition: opacity 0.3s ease-out, visibility 0.3s ease-out;
  background-color: rgba(0, 0, 0, 0.3);
}

@media screen and (max-width: 768px) {
  .overlay.show {
    visibility: visible;
    opacity: 1;
  }
}

@media screen and (max-width: 768px) {
  .columns.is-mobile>.column.is-narrow.is-mobile {
    width: 300px;
    transition: left 0.5s ease-in-out;
    position: fixed;
    top: 0;
    left: -300px;
    bottom: 0;
    z-index: 5;
  }
}

@media screen and (max-width: 768px) {
  .columns.is-mobile>.column.is-narrow.is-mobile {
    padding: 54px 0 0 0;
    outline: none;

    &.leftNavClosed {
      margin-left: -300px;
    }

    &.mobileNavOpen {
      left: 0;
      margin-left: 0;
    }
  }

  .grid-column-max-width {
    max-width: 140px;
  }
}

@media screen and (max-width: 1440px) {
  .grid-column-max-width {
    max-width: 140px;
  }
}

.grid-column-max-width {
  max-width: 240px;
}

.columns.is-mobile>.column.is-narrow:focus {
  outline: none;
}

.columns.is-mobile>.column.is-narrow {
  padding-right: 0;
  background-color: $sidebar-bg;
  max-width: 14rem;
  word-wrap: break-word;
}

// https://github.com/jgthms/bulma/issues/1190#issuecomment-342279409
.columns.is-variable.is-1 {
  margin-left: -0.25rem;
  margin-right: -0.25rem;

  &>.column {
    padding-left: 0.25rem;
    padding-right: 0.25rem;
    padding-top: 0.5rem;
    padding-bottom: 0.5rem;
  }
}

.columns.is-variable.is-4 {
  margin-left: -1rem;
  margin-right: -1rem;

  &>.column {
    padding-left: 1rem;
    padding-right: 1rem;
    padding-top: 1rem;
    padding-bottom: 1rem;
  }
}

@media screen and (min-width: 769px) {
  .columns.is-mobile>.column.is-narrow {
    transition: margin 0.5s ease-in-out;
    flex: 0 0 14rem;
  }
}

@media screen and (min-width: 769px) {
  .columns.is-mobile>.column.is-narrow.leftNavClosed {
    margin-left: -13rem;
  }
}

.menu-list,
.nav-submenu {
  display: none;
}

.menu-list.isOpen,
.nav-submenu.isSubNavOpen,
.nav-submenu.isSubNavOpenUnpinned {
  display: block;
}

.nav-menu {
  max-width: 128px;
  background: #EBEBEB;
}

.menu-list a:hover {
  background-color: lightgrey;
}

.nav-submenu-list a:hover {
  background-color: lighten(#004EEA, 40%) !important;
  color: #353535 !important;
}

.nav-submenu-list li a {
  padding: 1em 1.5em;
  font-size: 14px;
  display: flex;
  align-items: center;
}

.nav-submenu-text-container {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.menu-label:not(:last-child) {
  margin-bottom: 0;
}

.menu-label {
  display: flex;
  flex-direction: row;
  align-items: center;
}

.menu-label .top-nav-no-link {
  border-radius: 2px;
  flex: 1 1 auto;
  padding: 0.75em 1.1em 0.75em 0.75em;
  font-size: 14px;
  font-weight: 500;
}

.menu-label a {
  cursor: pointer;
  border-radius: 2px;
  flex: 1 1 auto;
  padding: 0.75em 1.1em 0.75em 0.75em;
  font-size: 14px;
  font-weight: 500;
}

.menu-label a,
.top-nav-no-link {
  color: #6F6F6F;
}

.menu-icon {
  font-size: 23px;
}

.menu-text {
  font-size: 10px;
  font-weight: 700;
}

.menu-label a.is-active,
.menu-list a.is-active,
.nav-submenu-manage-link.is-active,
.is-active-non-link {
  color: #004EEA;
}

.menu-label a.is-active,
.nav-submenu-list .is-active,
.menu-list a.is-active .menu-text,
.nav-submenu-manage-link.is-active,
.is-active-non-link {
  font-weight: 700 !important;
}

.nav-submenu-list .is-active {
  background: transparent !important;
}

.menu-label .icon {
  padding: 1em;
}

.menu-toggle .fa {
  cursor: pointer;
}

.menu-label a:hover,
.menu-label .top-nav-no-link:hover {
  color: lighten(#004EEA, 15%);
}

.menu-label.nav-control a:hover {
  background-color: transparent;
}

.box.leftnav {
  border-radius: 0;
  box-shadow: none;
}

.box.filter {
  margin-bottom: 0;
  padding: 0.9rem 0 0 0.9rem;
  background-color: transparent;
}

.bulma-loader-mixin {
  @include loader;
}

.m-auto {
  margin: auto;
}

.text-inherit {
  color: inherit !important;
}

.text-base {
  font-size: 1rem;
}

//Submenu styles
.nav-submenu {
  width: 300px;
  margin-top: 12px;
  background: $base-bg;
  border-left: 0.5px solid #DBDBDB;
  border-right: 0.5px solid #DBDBDB;
}

.nav-submenu.isSubNavOpenUnpinned {
  z-index: 25;
  position: fixed;
  top: 0;
  margin-top: 0;
  margin-left: 128px;
  bottom: 0;
}

.nav-submenu.isSubNavOpenUnpinned .nav-submenu-options-container {
  overflow-y: auto;
  overscroll-behavior: contain;
}

.nav-submenu-title-container {
  padding: 0.67em 1em;
  border-bottom: 0.5px solid #DBDBDB;
}

.nav-submenu-manage-area {
  margin-top: 8px;
}

.nav-submenu-manage-link {
  color: #6F6F6F;

  :hover {
    color: lighten(#004EEA, 15%);
  }
}

.nav-submenu-title-area {
  display: flex;
  justify-content: space-between;
}

.nav-submenu-title {
  font-weight: bold;
  font-size: 20px;
  text-transform: uppercase;
}

.nav-submenu-pin {
  font-size: 20px;
  cursor: pointer;
}

.nav-submenu-pin,
.nav-submenu-pin i {
  display: flex;
  align-items: center;
}

.nav-submenu-pinned-icon {
  color: #004EEA;
}

.submenu-icon {
  margin-right: 8px;
}

.submenu-icon-container {
  width: 15px !important;
}

/* END OF NAVIGATION STYLES*/

/* FILTER STYLES */

.dropdown-item.split-list {
  overflow-y: auto;
  max-height: 300px;
}

.dropdown-item.split-list ul li {
  padding-top: 0.375rem;
  padding-bottom: 0.375rem;
}

.dropdown-item.split-list ul li:hover {
  background-color: #dbdbdb;
}

.dropdown-item.split-list ul li:hover {
  background-color: #dbdbdb;
}

.dropdown-item.split-list ul li.is-active {
  background-color: #3273dc;
  color: #fff;
  border-bottom: 1px solid white;
}

.dropdown .dropdown-item ul li label {
  white-space: nowrap;
}

.filter .search {
  min-width: 300px;
}

.filter .search div {
  width: 100%;
}

// .filter .dropdown.is-active .dropdown-menu {
//   /*  padding-top: 0; */
// }

.filter .dropdown .button,
.gfbutton .button {
  margin-top: 0;
  margin-bottom: 7px;
  margin-right: 10px;
}

.dropdown-trigger:hover .button {
  border-color: #B3B0AD !important;
  color: #363636;
}

.filter .dropdown .dropdown-trigger {
  &.remove-btn-hidden {
    &>.button {
      padding-right: 12px;
      border-radius: 3px;
      border-right: 1px solid #B3B0AD;
    }
  }

  &>.button {
    border-right: none;
    background: #F5F5F5;
    border-color: #B3B0AD;
    border-radius: 3px 0 0 3px;
    margin-right: 0;
    padding-right: 6px;
  }
}

.filter .dropdown .dropdown-trigger>.button:focus:not(:active),
.button.is-focused:not(:active) {
  box-shadow: none;
}

.filter .dropdown .dropdown-trigger>.button.standalone {
  border-right: 1px solid #B3B0AD;
  border-radius: 3px 3px 3px 3px;
  margin-right: 10px;
  padding-left: 12px;
  padding-right: 12px;
}

.filter .dropdown .dropdown-trigger>.button.standalone:hover {
  border-color: #B3B0AD;
}

.filter .dropdown .dropdown-trigger>.button.remove {
  border-right: 1px solid #B3B0AD;
  border-left: none;
  border-radius: 0 3px 3px 0;
  margin-right: 10px;
  padding-left: 12px;
  padding-right: 12px;
}

.filter .dropdown,
.dropdown-trigger {
  outline: none;
}

.filter .dropdown.is-active .dropdown-trigger>.button {
  border-bottom: 0;
  background-color: #004EEA;
  color: #fff;
}

.dropdown-divider.bottom {
  margin-top: 0;
}

.dropdown-divider.top {
  margin-bottom: 0;
}

.filter.panel-block {
  justify-content: flex-start;
  align-content: space-between;
}

.filter.panel-block.is-wrapped {
  padding-top: 7px;
  border-left: none;
}

.filter.panel-block.is-wrapped.no-border {
  border: none;
}

.filterheading {
  font-weight: 600;
  margin: 5px 0;
}

.dashboard-icon-button {
  background: #F5F5F5;
  border-color: #B3B0AD !important;
}

.vmiddlediv {
  white-space: nowrap;
}

.vmiddlediv .button {
  margin-left: 1rem;
}

.filter .vmiddlediv .button {
  vertical-align: middle;
  margin-top: 0;
  margin-bottom: 0;
  margin-left: 1rem;
}

.action-buttons {
  .button-container {
    display: flex;
    justify-content: space-between;
    align-items: center;

    >.button {
      margin-left: 0;
      font-size: 0.75rem;
    }

    .button-group {
      .button {
        font-size: 0.75rem;
        height: 1.5rem;
      }

      margin-left: auto;
      display: flex;
      align-items: center;
    }
  }
}

/* FRAMEWORK LAYOUT STYLES */
#app {
  min-height: 100vh;
}

.main-content {
  flex: 1 0 auto;
}

.columns.main-content {
  margin-bottom: 0;
  margin-right: 0;
}

.column.main-content {
  padding-left: 0;
}

.footer {
  color: var(--headerTextColor);
  background-color: var(--headerBgColor);
  flex: 0 0 auto;
  padding-bottom: 1rem;
  padding-top: 1rem;
  z-index: 30;
}

.footer a {
  color: var(--headerTextColor);
}

.footer a:hover {
  text-decoration: underline;
}

.sticky-footer {
  height: 100vh;
  display: flex;
  flex-direction: column;
}

.sticky-spacer {
  flex: 1 0 auto;
}

html {
  min-height: 100vh;
  overflow-y: auto;
}

body {
  min-height: 100vh;
}

/* Widgets */
.widget-header {
  padding: 0 10px;
  height: 30px;
  background-color: #ffffff !important;
  border-bottom: none !important;
  border-radius: 4px !important;
}

/* HTML Widget Styles*/
.html-widget-container {
  width: 100%;
  height: 100%;
  overflow: hidden;
  position: relative;

  #preview {
    display: none;
  }
}

.html-widget-container .centered-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 100%;
}

/* Table Widget Styles*/
.table-widget-style-provider {
  height: 100%;
}

.table-widget-container {
  width: 100%;
  height: 100%;
  overflow: hidden;
  position: relative;

  .k-grid-content {
    margin-bottom: 16px;
    border-bottom: 1px solid rgba(0, 0, 0, 0.08);
    overflow-y: scroll;
  }

  /*Rich text content for Table widget with HTML column*/
  .richText {
    padding: .5em 0;
  }

  .richText p {
    padding: .2em 0 .3em 0 !important;
  }

  .richText img {
    padding: 0 10px;
  }

  .richText h1 {
    font-size: 20px;
    font-weight: bold;
    padding-bottom: 1em;
  }

  .richText h2 {
    font-size: 18px;
    padding-bottom: 1em;
  }

  .richText h3 {
    font-size: 16px;
    padding-bottom: 1em;
  }

  .richText h4 {
    font-size: 14px;
    font-weight: bold;
    padding-bottom: 1em;
  }

  .richText h5 {
    font-size: 12px;
    font-weight: bold;
    padding-bottom: 1em;
  }

  .richText h6 {
    font-size: 11px;
    font-weight: bold;
    padding-bottom: 1em;
  }

  .richText ul li {
    padding-left: 9px;
    /*Overrides for detailsList class*/
    width: 100%;
    position: inherit;
  }

  .richText ul {
    padding-left: 12px;
    padding-bottom: 1em;
  }

  .richText ol {
    list-style-type: decimal;
    padding-bottom: 1em;
    padding-left: 30px;
  }

  .richText table {
    display: table;
    border: 1px solid #666;
  }

  .richText tr {
    display: table-row;
  }

  .richText thead {
    display: table-header-group;
    background-color: #efefef;
  }

  .richText thead > :first-child {
    background-color: #dfdfdf;
  }

  .richText tbody {
    display: table-row-group;
  }

  .richText tfoot {
    display: table-footer-group;
  }

  .richText col {
    display: table-column;
  }

  .richText colgroup {
    display: table-column-group;
  }

  .richText td {
    display: table-cell;
    padding: 2px 2px;
    text-align: center;
  }

  .richText th {
    display: table-cell;
    padding: 2px 6px;
  }

  .richText th {
    font-weight: bolder;
    text-align: center;
  }

  .richText table {
    border-spacing: 2px;
  }

  .richText thead, tbody, tfoot {
    vertical-align: middle;
  }

  .richText td, th, tr {
    vertical-align: inherit;
  }

  .richText tr > :first-child {
    text-align: left;
  }
}

/* Map Widget Styles*/

.azure-maps-control-button {
  z-index: 10 !important;
}

.atlas-map {

  .map-copyright,
  .azure-map-logo {
    display: none !important;
  }
}

/* Preview Classes */

.mapPreview {
  height: calc(100% - 10px);
  padding: 5px 0;
}

.htmlPreview {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 100%;

  .html-widget-container {
    >* {
      display: none;
    }

    #preview {
      display: unset;
    }
  }
}

.htmlPreview .item {
  max-width: 50%;
}

.htmlPreview .item .content {
  width: 100%;
  text-align: center;
}

.htmlPreview .item .content.title {
  font-size: 1.75em;
}

.widgetDashPreview {
  padding: 0;
  width: 240px;
  height: 158px;
  padding: 0 5px;
}

.widgetDashPreview .columns {
  margin-top: 0;
  margin-bottom: 0;
  margin-left: 5px;
  margin-right: 5px;
}

.widgetDashPreview .widgetError {
  height: 100%;
  padding: 0;
}

.widgetDashPreview .subtitle {
  font-size: 0.75em;
  word-break: break-word;
}

.widgetError {
  display: flex;
  height: 100%;
  justify-content: center;
  align-items: center;
}

.widgetLoader {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
  flex-direction: column;
  padding: 0;
  width: 50%;
  margin: 0 auto;
}

.tableWidgetPreviewContainer {
  width: 100%;
  height: 100%;
  padding: 15px 10px;

  .tableWidgetPreview {
    border: 1px solid rgba(0, 0, 0, 0.08);
    width: calc(100% - 20px);
    height: calc(100% - 30px);

    & tbody tr td {
      width: initial;
      min-width: initial;
    }
  }
}

.page-loading-container {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
  flex-direction: column;
  padding: 0 20px;
  width: 50%;
  margin: 0 auto;
}

.app-loading-container {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
  flex-direction: column;
  padding: 0 20px;
  width: 50vw;
  margin: 0 auto;
}

/* END OF FRAMEWORK LAYOUT STYLES */

/* Utitlity Styles */

.full-center-content {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
  width: 100%;
}

.d-flex {
  display: flex;
}

.row {
  display: flex;
  flex-direction: row;
}

.justify-content-center {
  justify-content: center;
}

.justify-content-between {
  justify-content: space-between;
}

.align-items-center {
  align-items: center;
}

.align-items-start {
  align-items: flex-start;
}

.flex-column {
  flex-direction: column;
}

.flex-row {
  flex-direction: row;
}

.w-100 {
  width: 100%;
}
.w-50 {
  width: 50%;
}

.mb-3 {
  margin-bottom: 0.75rem;
}

.border-0 {
  border: 0;
}

.text-wrap {
  text-wrap: wrap !important;
}

/* End of Utitlity Styles */

.modal-box {
  height: 90vh;

  .tab-container .k-tabstrip .k-content {
    overflow: hidden;
  }
}

/* Highchart table styles */

.highcharts-data-table {
  width: 100%;
  height: 100%;
  position: relative;
  overflow-y: auto;
}

.highcharts-data-table table {
  border-collapse: collapse;
  border-spacing: 0;
  background: white;
  min-width: 100%;
  margin-top: 0;
  font-family: sans-serif;
  font-size: 0.9em;
}

.highcharts-data-table td,
.highcharts-data-table th,
.highcharts-data-table caption {
  font-size: 0.9rem !important;
  border: 1px solid #c0c0c0;
  padding: 0.5em;
}

.highcharts-data-table th.number,
.highcharts-data-table td.number,
.highcharts-data-table th.text,
.highcharts-data-table th.text {
  text-align: right;
  display: table-cell;
  position: relative;
  border-radius: 0;
  background-color: transparent;
  margin-right: 0;
}

.highcharts-data-table tr:nth-child(even),
.highcharts-data-table thead tr {
  background: #f8f8f8;
}

.highcharts-data-table tr:hover {
  background: #eff;
}

.highcharts-data-table caption {
  display: none;
  border-bottom: none;
  font-size: 1.1em;
  font-weight: bold;
}

.menu-popup-content {
  box-shadow: none !important;
}

/* Filters styles */
.dropdown-menu.dropdown-menu-dropdownMenu,
.dropdown-content {
  outline: none !important;

  input[type="checkbox"] {
    margin-right: 0.75em;
  }
}

.ReactVirtualized__Grid.ReactVirtualized__List {
  outline: none;
}

.searchableMultiselect-checkboxItem {
  padding: 6px 0;
  cursor: pointer;

  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  margin-right: 0.5rem;

  &:hover {
    background: #dbdbdb;
  }
}

/* End of Filters styles */

/* Dropdown MultiSelect */
.dropdownMultiSelect-wrapper.select.is-fullwidth {
  height: 100%;
}

.dropdownMultiSelect {
  width: 100%;
  &.filter {
    & .k-multiselect-wrap.k-floatwrap {
      padding: 0 4px 0 5px !important;
    }
    & li.k-button span {
      font-size: 12px;
    }
  }

  &.hasValue {
    & .k-multiselect-wrap.k-floatwrap {
      padding: 3.1px 40px 3.1px 5px;

      & .k-searchbar {
        width: unset;
      }
    }
  }

  & .k-multiselect-wrap.k-floatwrap {
    display: flex;
    align-items: center;
    padding: 5px 40px 5px 9px;
    border: 1px solid #dbdbdb;
    border-radius: 4px;
    cursor: pointer;

    & ul.k-reset {
      display: flex;
      flex-wrap: wrap;

      & li.k-button {
        margin: 2px;
        padding: 0px 8px;
        font-size: 1rem;
        color: #4a4a4a;

        & .k-select {
          display: flex;
          margin-left: 3px;
          align-items: center;
        }
      }
    }

    // textfield
    & .k-searchbar {
      width: 100%;

      & input {
        height: 24px;

        &::placeholder {
          color: #363636;
          font-size: 1rem;
        }
      }
    }

    // x icon
    & .k-clear-value.k-i-close {
      position: relative;
      top: 0;
      right: 0;
      justify-self: flex-end;
      margin-left: auto;
      height: 100%;
      display: inline-flex;
    }

    &:hover {
      border: 1px solid #b5b5b5;
    }
  }
}

/* dropdown options */
.k-list-scroller {
  & ul.k-list {
    & li.k-item {
      &.k-state-selected {
        background-color: hsl(217, 71%, 50%);
      }
    }
  }
}

/* Dropdown MultiSelect */

/* Widget Stepper Styles */
@media screen and (min-width: 769px),
print {
  .field-body {
    padding-right: 10%;
  }
}

.field-body--no-padding {
  .field-body {
    padding-right: 0;
  }
}

.field-body--grow-4 {
  .field-body {
    flex-grow: 4;
  }
};

.MultiSelectInput .field-body .select .dropdown-trigger .button {
  .icon {
    margin-left: 0.5em;

    .flaticon-down {
      display: none;
    }
  }
}

.stepper-button-hidden {
  display: none;
}

.step-is-visible {
  display: block;
}

.step-is-hidden {
  display: none;
}

.k-stepper .k-step-error .k-step-indicator {
  border-color: red;
  color: #ffffff;
  background-color: red;
}

// Dashboard Template Assign Modal
.k-animation-container.assign-template-popup,
.k-animation-container.modal-popup {
  box-shadow: none;

  .menu-popup-content {
    border-color: transparent;
    background-color: transparent;
  }

  .dropdown-item.split-list {
    overflow-y: auto;
    max-height: 200px;
  }

  .dropdown-trigger button {
    width: calc(100vw - 35vw);
    display: flex;
    justify-content: space-between;
  }
}

.k-animation-container.modal-popup {
  .menu-popup-content {
    &.no-margin {
      margin: 0;
    }
  }

  .dropdown-trigger {
    width: 100%;
    display: flex;

    & .button:first-child {
      width: 100%;
    }

    & .button.remove {
      max-width: 36px;
    }
  }
}

// Management Table overrides
//right aligned headers have no padding
.k-grid-header .k-header .has-text-right {
  padding-right: 0;
}

.k-grid td {
  border-bottom: 1px solid #BEBEBE;
}

.k-grid th,
k-grid td {
  padding: 0.25em 0.75em;
}

.k-grid th {
  font-weight: bold;

  &.wrap {
    white-space: normal;
  }
}

.k-grid-header .k-header>.k-link>.k-icon.k-i-sort-desc-sm,
.k-grid-header .k-header>.k-link>.k-icon.k-i-sort-asc-sm {
  vertical-align: text-bottom;
  margin-left: calc(8px - 1px);
}

.k-widget.k-grid,
.k-widget.k-grid.managewidgets {
  .k-pager-wrap.k-floatwrap.k-widget.k-grid-pager {
    // order: 2;
    border-bottom: 1px solid rgba(0, 0, 0, 0.08);
    padding: 0.1em 0.5em;
  }

  .k-grid-header {
    // order: 3;
  }

  .k-grid-container {
    // order: 4;
  }

  /*  .k-grid-header .k-header > .k-link:first-child {
    text-align: center;
  }*/
}



// Manage Dashboard Templates
.k-tabstrip {
  border: none;

  .k-tabstrip-items {
    display: flex;

    .k-item {
      min-width: 180px;
      margin-left: 0.5rem;
      text-align: center;
      border: 1px solid #dbdbdb;
      border-bottom: none;
      color: #363636;

      &.k-state-active {
        border: 1px solid $primary;
        background-color: $primary;
        color: #fff;
      }
    }
  }

  .k-content {
    padding: 0;
    border: 1px solid rgba(0, 0, 0, 0.08);
    border-top: 3px solid $primary;
    border-top-left-radius: 4px;
    border-top-right-radius: 4px;

    .k-animation-container {
      width: 100%;
    }
  }
}

.button.grouped.is-loading::after {
  left: calc(30% - (1em / 2));
}

.widget-container {
  & .input-container div:first-child {
    width: 100%;
  }

  & .title-container {
    max-width: calc(100% - 24px);
  }

  & .button-group {
    & .icon {
      width: auto;
      font-size: .83rem;
      color: gray;
      cursor: pointer;

      &.blue {
        color: #004EEA;
      }

      & .num {
        margin-left: 2px;
        font-size: .75rem;
      }

      &:not(:last-child) {
        margin-right: 10px;
      }
    }
  }
}

.input {
  &.inline-editable {
    border: 0;
    padding: 0;
    box-shadow: none;
    background: transparent;
    border-bottom: 1px solid $info;
    color: $info;
    font-weight: 700;
    font-size: 0.8rem;
  }
}

.icon.icon-black {
  color: #363636 !important;
}

.icon-buttons {
  position: absolute;
  left: 0;

  .button {
    height: 45px;
    width: 45px;
    font-size: 1.25rem;
  }
}

.cursor-pointer {
  cursor: pointer;
}

.highcharts-drillup-button {
  display: none;
}

.content-spacing-container {
  padding-left: 30px;
  padding-right: 30px;
}

.dashboard-content-spacing-container {
  padding-left: 18px;
  padding-right: 18px;
}

.modal-popup {
  .FilterManager {
    padding-right: 0 !important;
    margin-left: 6px !important;

    .value-display {
      max-width: none !important;
    }
  }
}

.WidgetLevelFilters {
  .FilterManager {
    border: none;
    padding: 0;
    margin: 0;
  }

  .box {
    border: none;
    padding: 0;
  }
}

.FilterManager {
  margin-bottom: .5rem;
  padding-top: 16px;
  padding-bottom: 16px;
  padding-right: 30px;
  background: #f5f5f5;
  border-top: 0;
  border-left: 0;
  border-right: 0;
  font-size: 0.75rem;

  .hidden {
    display: none;
  }

  .PageFilters,
  .ApplyBtn {
    &.disabled {
      pointer-events: none;
      color: #B3B0AD;

      .value-display,
      .dropdown-trigger .icon>i {
        color: #B3B0AD !important;
      }

      .dropdown-trigger>button {
        border-color: #B3B0AD !important;
      }

      .button {
        color: #B3B0AD !important;
        border-color: #B3B0AD !important;
      }
    }
  }
  .GlobalFilters {
    .filter-group {
      min-width: unset;
      max-width: unset;
      .filter-group-dropdown > .dropdown-trigger {
        font-size: 1rem;
        > button {
          width: auto !important;
          height: 32px !important;
        }
        .dropdown-label {
          height: auto !important;
          .value-display {
            min-width: 210px !important;
            max-width: 220px !important;
            -webkit-line-clamp: 1 !important;
          }
        }
        .icon {
          height: auto !important;
        }
      }
    }
    .filter-group-list-container {
      color: #363636;
    }
  }

  .ApplyBtn {
    padding-bottom: 4px !important;
  }

  .columns-container {
    width: 100%;
    align-items: flex-end;
  }

  .dropdown-menu {
    z-index: 9999;
    padding: 0;

    &.content-visible {
      padding: 4px 1px 1px 1px;
    }
  }

  .action-button {
    border: 0.5px solid #B3B0AD;
    font-weight: 600;

    &.active {
      border: 0.5px solid #004EEA;
      color: #fff;
      background-color: #004EEA;
    }
  }

  .filter-interval {
    &.field.has-addons {
      width: 314.75px;

      .control {
        width: 100%;
        font-size: inherit;

        &.selected .button {
          color: #fff;
          background-color: #004EEA;
        }
      }

      .button {
        width: 100%;
        height: 31.5px;
        font-size: inherit;
        border-color: #B3B0AD;
      }
    }
  }

  .filter-group {
    min-width: 227.11px;
    max-width: 227.11px;

    &.popup-filter-group-container {
      min-width: unset;
      max-width: unset;
    }

    .dropdownMenu {
      .icon-container {
        position: absolute;
        z-index: 1;
        width: 100%;
        height: 100%;
        display: flex;
        align-items: center;
        justify-content: center;
      }

      &.is-active {
        >.dropdown-trigger {
          >button {
            color: #fff;
            background-color: #004EEA !important;
          }

          .icon>i {
            color: #fff !important;
          }
        }
      }

      &.loading {
        & .dropdown-trigger {
          & .button {
            pointer-events: none;

            & span {
              visibility: hidden;
            }
          }
        }
      }
    }

    .filter-group-dropdown.dropdownMenu {
      width: 100%;

      // max-width: 216px;
      // for all
      .dropdown-trigger {
        width: 100%;

        >button {
          border: 0.5px solid #B3B0AD;
          width: 100%;
          font-size: inherit;

          .dropdown-label {
            flex-grow: 1;
          }

          >span {
            display: flex;
            align-items: center;

            .selected-display {
              width: 280px;
              overflow: hidden;
              text-overflow: ellipsis;
              text-align: left;
            }
          }
        }

        .icon>i {
          color: #004EEA;
        }

        &:hover {
          >button {
            background-color: #E5EDFD;
          }
        }
      }

      // only for direct descendant
      >.dropdown-menu {
        .dropdown-content {
          border: 0.5px solid #B3B0AD;
          box-shadow: none;
        }

        >.dropdown-content {
          box-shadow: 1px 1px 6px rgba(0, 0, 0, 0.25);
        }
      }

      >.dropdown-trigger {
        >button {
          height: auto;
          padding: 0 8px;
          justify-content: space-between;
          white-space: normal;

          >span {
            height: 42px;
            display: flex;
            align-items: center;

            .value-display {
              min-width: 170px;
              max-width: 180px;
              overflow: hidden;
              text-overflow: ellipsis;
              text-align: left;
              display: -webkit-box;
              -webkit-line-clamp: 2;
              -webkit-box-orient: vertical;

              &.small {
                width: 120px;
                min-width: 120px;
              }
            }
          }
        }
      }

      .dropdown-item .vmiddlediv {
        align-items: center;

        .btn-cancel,
        .btn-clear {
          color: #004EEA;
          padding: 0;
          border: none;
          font-weight: 600;
          border-bottom: 2px solid #004EEA;
          border-radius: 0;
          height: 1.5em;
        }

        .btn-okay {
          width: 100px;
          background-color: #004EEA;
          border: 0.5px solid #004EEA;
          color: #fff;
          font-weight: 600;
        }
      }

    }
  }

  .filter-group-list-container {
    display: flex;
    flex-direction: column;
    padding: 1rem;

    .filter-group-list {
      p.column {
        margin-right: .5rem;
      }

      &:not(:last-child) {
        margin-bottom: 1rem;
      }

      .dropdown-item {
        font-size: 0.75rem;

        input {
          font-size: 0.75rem;

          &[type="checkbox"] {
            vertical-align: text-bottom;
          }
        }

        .icon.is-small.is-right {
          font-size: 0.75rem;
        }
      }
    }
  }

  .k-slider-horizontal {
    width: 319.98px;

    .k-tick {
      z-index: 9 !important;

      &:nth-child(1),
      &:nth-child(5),
      &:nth-child(7),
      &:nth-child(9),
      &:nth-child(10) {
        .k-label {
          .text {
            &::after {
              left: -6px;
            }
          }
        }
      }

      &:nth-child(2) {
        .k-label {
          .text {
            &::after {
              left: -7.5px;
            }
          }
        }
      }

      &:last-child {
        .k-label {
          .text {
            &::after {
              left: -4.7px;
            }
          }
        }
      }
    }

    .k-label {
      top: 8px;
      color: #fff;

      .text {
        padding: 1px 0;
        border-radius: 50%;
        background-color: #004EEA;
        position: relative;

        &::after {
          content: ' ';
          width: 20px;
          height: 20px;
          display: block;
          background: #004EEA;
          left: -6.5px;
          top: -3px;
          position: absolute;
          z-index: -1;
          border-radius: 50%;
        }
      }
    }

    .k-slider-selection {
      background-color: #004EEA;
    }

    .k-draghandle {
      background-color: #004EEA;
      border-color: #004EEA;
    }
  }
}

.custom-icon-container {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
}

.widget-settings-menu-popup {
  .k-menu-item:hover {
    background-color: #E5EDFD;
  }
}

.classic-iframe-container {
  width: 100%;
  height: calc(100vh - (52px + 80px + 12px)); // top, bottom, padding

  >iframe {
    width: 100%;
    height: 100%;
  }
}

.widget-custom-icon {
  justify-content: flex-start;
  width: 19px;
  padding-left: 2px;
  font-size: 0.8rem;
}

.input-disable {
  height: 30px
}

.widget-name-custom-container {
  display: flex;
  align-items: center;
}

.widget-name-custom-left {
  display: flex;
  align-items: center;
  width: 13%;

  >span {
    margin-left: 5px;
  }
}

.widget-category-container {
  display: flex;
  margin: 2.5px 0;
  height: 100%;
  align-items: center;
}

.widget-category-container:not(:last-child) {
  margin-bottom: 3px;
}

.widget-category-container-centered {
  justify-content: center;
}

.widget-category {
  border-radius: 10px;
  padding: 2.5px 5px;
  background: grey;
  color: #FFFFFF;
}

.widget-category.Energy {
  background: #7ABEA7 !important;
}

.widget-category.Comfort {
  background: #703BE0 !important;
}

.widget-category.Maintenance {
  background: #F2823C !important;
}

.widget-selector-container {
  padding: 5px;
  cursor: pointer;

  &:hover {
    background: #E7EEFD;
    ;
  }
}

.widget-selector-type-area {
  margin-bottom: 30px;
}

.add-widget-cell {
  height: inherit;

  &:hover {
    background: transparent !important;
  }
}

.k-master-row:has(> .add-widget-cell),
.add-dashboard-widget-modal-container .colCheckbox {
  &:hover {
    background: #E7EEFD !important;
  }
}

.add-dashboard-widget-modal-container .k-header .k-link {
  display: flex;
  justify-content: center;
}

.add-dashboard-widget-modal-container .colCheckbox {
  vertical-align: middle !important;
  padding-top: 1rem !important;
}

tr:has(> .add-widget-cell) {
  height: 1px;
}

.diagnostic-report-modal-header {
  margin-bottom: 1rem;
}

.diagnostic-report-notes-container {
  margin: 2% 12%;
  font-size: 0.75rem;

  p {
    min-height: 1rem;
  }

  ul {
    padding-left: 1rem;
    list-style: disc;
    list-style-position: inside;

    ul {
      padding-left: 1.5rem;
    }
  }
}

.deployments-status-badge.tag {
  width: 128px;
  font-weight: bold;
  color: #fff !important;

  &.staging{
    background-color: #E5B027;
  }

  &.implementation {
    background-color: #CE7625;
  }

  &.data-access-enabled {
    background-color: #99B8F7;
  }

  &.diagnostics-enabled {
    background-color: #004EEA;
  }

  &.completed {
    background-color: #7ABEA7;
  }

  &.blocked {
    background-color: #D95029;
  }

  &.cancelled {
    background-color: #AEAEAE;
  }
}

.equipment-configuration-status-badge.tag {
  width: 128px;
  font-weight: 500;
  color: #fff;
  border-radius: 20px;
}

.deployment-progress-bar .highcharts-point {
  stroke: none;
}

.diagnostic-report-results-container {
  margin: 24px 10%;
  font-size: .75rem
}

.diagnostic-report-modal-container {
  &>.field.is-horizontal {
    margin-bottom: 0 !important;
  }

  .task-tab {
    margin: 24px;
  }
}

.task-status-badge.tag {
  width: 84px;
  font-weight: bold;
  color: #fff !important;

  &.is-info {
    background-color: #004eea;
  }

  &.is-link {
    background-color: #99B8F7;
  }

  &.is-success {
    background-color: #7ABEA7;
  }

  &.is-dark {
    background-color: #AEAEAE;
  }

  &.is-dark-gray {
    background-color: #868686;
  }

  &.is-warning {
    background-color: #E5B027;
  }
}

.tab-container {
  padding: 0;
  box-shadow: none;
  overflow: hidden;
  border: 1px solid #dbdbdb;

  .k-tabstrip {
    font-size: 1rem;

    .k-tabstrip-items {
      .k-item {
        margin-left: 0;
        border-top: none;
        background: transparent;
        color: inherit;
        border: 1px solid transparent;

        &.k-state-active {
          font-weight: bold;
          color: $primary;
          border-bottom: 1px solid $primary;
        }

        .k-link {
          display: block;
        }
      }
    }

    .k-content {
      overflow-y: scroll;
      overflow-x: auto;
      border: 0;
    }
  }
}

.create-task-footer-container {
  width: 100%;
  position: relative;
  justify-content: center;
  align-items: center;

  .field-label {
    margin-right: 0;
  }

  .field-body {
    margin-right: 1.5rem;
    text-align: right;
    display: block;
  }

  .buttons {
    position: absolute;
    margin: auto;

    .button {
      margin-bottom: 0;
    }
  }
}

.create-task-footer-email-container {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  margin-right: auto;
  padding: 10px 20px;
}

.create-task-footer-container .checkbox {
  width: 25px;
  height: 25px;
}

.create-task-footer-container .field-label {
  flex-basis: content;
  font-size: 16px;
}

.checkbox-with-no-icon {
  display: flex;
  align-items: center;
  .k-checkbox {
    width: 25px!important;
    height: 25px !important;
}
  .k-checkbox::before {
    content: "";
}
  .k-checkbox:checked {
    border-color: #004eea;
    color: #ffffff;
    background-color: #004eea;
  }
}

.widget-category-type {
  font-size: 1.5rem;
}

.diagnostic-options-area {
  margin-left: 6%;
}

.diagnostic-options-header {
  font-weight: bolder;
}

.diagnostic-options-datepicker .k-widget.k-datepicker.is-horizontal {
  width: 100%;
}

.diagnostic-options-equipment {
  display: flex;
  align-items: center;
}

.diagnostic-options-expander-area {
  margin: 2% 0 2% 18%;
}

.diagnostic-options-expander {
  color: #004EEA;

  &:hover {
    cursor: pointer;
  }
}

.text-primary-blue {
  color: #004EEA;
}

.hover-text-primary-blue:hover {
  color: #004EEA;
}

.highcharts-tooltip-container {
  z-index: 9999 !important;

  .highcharts-tooltip.sparkline-tooltip>span {
    width: 230px !important;
    overflow: auto !important;
    white-space: normal !important;
    font-family: inherit !important;
  }
}

.deployment-grid,
.task-grid,
.diagnostics-grid,
.task-grid,
.building-grid,
.equipment-grid,
.point-grid,
.equipment-configuration-grid {
  .k-header>div>div {
    right: 0 !important;
  }

  .k-header {
    .k-column-resizer {
      border-right: 1px solid rgba(0, 0, 0, 0.08);
    }
  }
}

.diagnostics-grid {
  .k-detail-cell {
    padding-left: 0;
    padding-right: 0;

    .k-grid-header {
      display: none;
    }
  }
}

.k-column-list {
  .k-column-list-item>span {
    display: inline-flex;

    >input {
      width: 1rem;
      height: 1rem;
      min-width: 1rem;
      min-height: 1rem;
    }
  }
}

.column-popup-content {
  .column-title {
    padding: 1em;
    font-weight: 700;
  }
}

.edit-equipment-modal .equipment-profile-container,
.equipment-report-modal-container .equipment-profile-container {
  font-size: 1rem;
}

.equipment-profile-container {
  font-size: .75rem;

  .k-grid-wrapper .k-widget.k-grid {
    border-left: 0;
    border-right: 0;

    .k-dropdown-wrap {
      background: #fff;
    }
  }

  .k-grid-header-wrap {
    background-color: #fff;
  }
}

.notes-cell-popup-content {
  padding: 10px;
}

.notes-cell-icon {
  border: none;
  background-color: transparent;
}

.notes-cell-icon:hover {
  color: $primary;
}

.grid-single-row-no-header {
  .k-widget.k-grid {
    border-left: 0;
    border-right: 0;

    .k-grid-header {
      display: none;
    }

    .k-master-row:hover {
      background-color: transparent;
    }
  }
}

.diagnostics-top-results-filter {
  border-radius: 4px;
  box-shadow: none;
  border: none;
  background-color: transparent;

  .card-content {
    padding: 0.5rem 0rem;
    align-items: center;
    column-gap: 0.5rem;

    .topValue,
    .topBy {
      align-items: center;
      column-gap: 0.5rem;
    }

    .icon {
      font-size: 24px;
      color: #004EEA;
    }

    input {
      font-weight: 600;
      width: 60px;
      text-align: center;
    }

    select {
      font-weight: 600;
    }

    label {
      white-space: nowrap;
      font-size: 0.75rem;
    }
  }
}

.equipment-info-grid {
  padding-left: 26px;
}

.borderless-grid {
  .k-grid {
    border-top: none;
    border-left: 0;
    border-right: 0;

    tbody tr td {
      padding-top: 12px;
      padding-bottom: 12px;
      border-bottom: none;
    }
  }

  .k-grid-header .k-header>.k-link {
    padding-bottom: 12px;
  }
}

.homepage,
.deployment-details {
  margin: 0;
  padding: 0px 22px 8px 22px;

  &>.column {
    padding-left: 0.5rem;
    padding-right: 0.5rem;
    padding-top: 0.5rem;
    padding-bottom: 0.5rem;

    .content {
      overflow: hidden;
      height: 100%;
      display: flex;
      flex-direction: column;
      border-radius: 8px;
      background-color: #fff;
      box-shadow: 1px 1px 6px rgba(0, 0, 0, 0.25);

      .summary-wrapper {
        display: flex;

        &>.summary {
          width: 50%;
        }
      }

      .summary {
        padding: 0 0.5rem;
        column-gap: 0.5rem;
        display: flex;
        align-items: center;

        .icon {
          font-size: 1.5rem;
        }

        .value {
          font-size: 2rem;
          font-weight: 600;
        }

        .label {
          margin-top: 0.5rem;
          font-weight: 500;
        }

        p {
          margin: 0;
        }
      }

      .widget-renderer-wrapper {
        height: 100%;
        display: flex;
        flex-direction: column;
        row-gap: 1rem;

        .widget-renderer {
          &.table {
            padding: 0 8px 8px 0;

            .k-widget.k-grid {
              height: 100% !important;
              border: 0;

              .k-header {
                border-bottom-width: 1px;
              }

              .k-grid-norecords td {
                padding: 1rem;
                color: #a1a1a1;
                text-align: center;
              }

              // scroll & border
              .k-grid-header-wrap {
                border-right: 0 !important;
              }

              .k-grid-header {
                padding-right: 0 !important;
              }

              .k-grid-content.k-virtual-content {
                overflow: hidden;
              }
            }
          }

          .floating-percentage {
            position: absolute;
            z-index: 9;
            right: 16px;
            font-weight: 600;
            color: #144597;
            font-size: 1.25rem;
          }
        }
      }

      .table-label-wrapper {
        padding: 0 0.5rem;

        .table-label {
          margin: 0;
          font-weight: 600;
          display: inline-flex;
          align-items: center;

          .icon {
            margin-left: 0.5rem;
            padding: 0.5rem;
            height: 1rem;
            width: 1rem;
            border: 1px solid #3273dc;
            color: #3273dc;
            border-radius: 50%;
          }

          a {
            display: inline-flex;

            &.disabled {
              pointer-events: none;
              color: #B3B0AD;

              .icon {
                color: #B3B0AD;
                border-color: #B3B0AD;
              }
            }
          }
        }
      }

      .placeholder-wrapper {
        width: 100%;
        height: 100%;
        display: flex;
        align-items: center;
        justify-content: center;
        column-gap: 1rem;
        color: #a1a1a1;

        .icon {
          font-size: 3rem;
          margin-right: 1rem;
        }

        .title {
          padding-bottom: 1rem;
          margin-bottom: 1rem;
          font-size: 1.5rem;
          font-weight: 600;
          color: inherit;
        }

        .subtitle {
          font-size: 1rem;
          color: inherit;
        }
      }
    }
  }

  .layout-wrapper {
    padding: 0 .5rem;

    .row-item {
      display: flex;
      justify-content: space-between;
      border-bottom: 1px solid hsl(0, 0%, 90%);
      padding: .25rem .5rem;

      &.no-border {
        border-bottom: none;
      }

      .item-value {
        width: 80px;
        text-align: center;
        border-radius: 12px;
        font-weight: 600;
        background: #F7DCD4;
        color: #D84C24;
      }
    }
  }
}

.bulk-edit-equipment-variables {
  .title.is-6 {
    font-size: 18px;
  }

  .field.is-narrow {
    width: 416px
  }

  .dropdown-label {
    overflow: hidden;
    text-overflow: ellipsis;
  }

  .field-label, .dropdown-label, select {
    font-size: 14px;
  }

  .field.is-horizontal .field-label {
    text-align: left;
  }

  .dropdown-trigger .button {
    width: 416px;
    height: 31.5px;
    padding-left: 7.75px;
    justify-content: flex-start;
  }

  .select::after {
    border-width: 2px !important;
    margin-top: -0.56em !important;
  }

  .dropdown-item {
    font-size: 14px;

    input {
      font-size: 14px;

      &[type="checkbox"] {
        vertical-align: text-bottom;
      }
    }

    .icon.is-small.is-right {
      font-size: 14px;
    }

    #clearNote {
      height: 31.5px;
    }
  }
}

// Bulk edit building variables modal
.bulk-edit-building-variables {
  .title.is-6 {
    font-size: 18px;
  }

  .field.is-narrow {
    width: 416px
  }

  .dropdown-label {
    overflow: hidden;
    text-overflow: ellipsis;
  }

  .field-label, .dropdown-label, select {
    font-size: 14px;
  }

  .field.is-horizontal .field-label {
    text-align: left;
  }

  .dropdown-trigger .button {
    width: 416px;
    height: 31.5px;
    padding-left: 7.75px;
    justify-content: flex-start;
  }

  .select::after {
    border-width: 2px !important;
    margin-top: -0.56em !important;
  }

  .dropdown-item {
    font-size: 14px;

    input {
      font-size: 14px;

      &[type="checkbox"] {
        vertical-align: text-bottom;
      }
    }

    .icon.is-small.is-right {
      font-size: 14px;
    }
  }
}

form .field.is-horizontal .field-body .control {
  // NumberInput component
  .k-numerictextbox {
    width: auto;
    .k-numeric-wrap {
      border: none;
      .k-input {
        padding-bottom: calc(0.375em - 1px);
        padding-left: calc(0.625em - 1px);
        padding-right: calc(0.625em - 1px);
        padding-top: calc(0.375em - 1px);

        height: 2.25em;
        line-height: 1.5;

        border: 0.8px solid rgb(219, 219, 219);
        box-shadow: inset 0 1px 2px rgba(10, 10, 10, 0.1);
        border-radius: 4px;
        &:hover {
          border-color: hsl(0, 0%, 71%);
        }
        &:focus {
          border-color: hsl(217, 71%, 53%);
          box-shadow: 0 0 0 0.125em rgba(50, 115, 220, 0.25);
        }
      }
    }
  }

  // DatePickerInput component
  .k-datepicker {
    width: auto;
    .k-picker-wrap {
      border: 0.8px solid rgb(219, 219, 219);
      box-shadow: inset 0 1px 2px rgba(10, 10, 10, 0.1);
      border-radius: 4px;

      .k-dateinput-wrap {
        border-top-left-radius: 4px;
        border-bottom-left-radius: 4px;
      }
      .k-select {
        border-top-right-radius: 4px;
        border-bottom-right-radius: 4px;
      }

      &:hover {
        border-color: hsl(0, 0%, 71%);
      }
      &.k-state-focused {
        border-color: hsl(217, 71%, 53%);
        box-shadow: 0 0 0 0.125em rgba(50, 115, 220, 0.25);
      }
    }
  }
}


.configuration-note-textarea {
  .field {
    width: 416px;
    max-width:416px;

    textarea {
      min-height: 25px;
      overflow-y: auto;
    }
  }
}

.equipment-details-reference-grid .notes-cell-icon {
  color: black !important;      /* Force a static color */
  cursor: default !important;   /* Force default cursor */
}

.equipment-details-reference-grid .notes-cell-icon:hover {
  color: black !important;      /* Prevent hover color change */
}


.custom-value-searchable-multi-select {
  border: 1px solid #ccc;
  padding: 2px 20px 2px 8px;
  border-radius: 4px;
  display: flex;
  flex-wrap: wrap;
  gap: 8px;
  position: relative;
  background-color: #fff;
  width: 100%;
  box-shadow: 0px 1px 3px rgba(0, 0, 0, 0.1);

  .clear-all-button {
    position: absolute;
    right: 10px;
    top: 4px;
    padding-top: 3px;
    background-color: transparent;
    border:none;
    cursor: pointer;
  }

  .selected-items-container {
    display: flex;
    flex-wrap: wrap;
    gap: 6px;
    flex-grow: 1;
    align-items: center;
    width: 100%;

    .selected-item {
      background-color: #f0f0f0;
      border-radius: 4px;
      padding: 4px 8px;
      display: flex;
      align-items: center;
      font-size: 14px;

      .remove-item-button {
        background: none;
        border: none;
        font-size: 12px;
        margin-left: 4px;
        cursor: pointer;
        color: #666;
      }
    }

    .main-input {
      flex-grow: 1;
      min-width: 100px;
      max-width: 100%;
      border: none;
      outline: none;
      padding: 4px 8px;
      font-size: 14px;
    }
  }

  .dropdown-container {
    position: absolute;
    top: calc(100% + 4px);
    left: 0;
    width: 100%;
    border: 1px solid #ccc;
    box-shadow: 0px 1px 3px rgba(0, 0, 0, 0.1);
    background-color: #fff;
    max-height: 200px;
    overflow-y: auto;
    z-index: 1000;
    padding: 8px;

    .search-input-container {
      position: relative;

      .main-input {
        width: 100%;
        padding: 8px 30px 8px 8px; /* Right padding for icon space */
        box-sizing: border-box;
      }

      .icon.is-right {
        position: absolute;
        right: 10px;
        top: 50%;
        transform: translateY(-50%);
        color: #666;
        cursor: pointer;
        pointer-events: auto; /* Ensure the icon is clickable */
      }
    }

    .checkbox-list-item {
      display: flex;
      align-items: center;
      cursor: pointer;
      padding: 4px 8px;

      input[type="checkbox"] {
        margin-right: 8px;
      }
    }

    .checkbox-list-item.selected {
      background-color: #e0f7fa;
    }
  }
}
.kendo-multi-select {
  input {
    font-size: 1rem !important;
  }
}

.labels-dropdown-popup {
  z-index: 10005;
}

.multi-select-options li {
    font-size: 1rem;
}

// [Edge] Fix for the Highcharts data labels not showing in the Configuration Chart
#configurationChartSection {
  .Highchart svg {
    > g.highcharts-data-labels.highcharts-series-0.highcharts-treegraph-series.highcharts-tracker
    > .highcharts-label.highcharts-data-label {
      visibility: visible !important;
    }
  }
}
